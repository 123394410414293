import styled from 'styled-components';

const CardContainer = styled.div`
width: 100%;
display:flex;
gap:32px;
flex-wrap:wrap;
`;

export { CardContainer };
