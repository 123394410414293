/* eslint-disable no-console,no-undef,camelcase */
import React from 'react';
import {ChampionTab,ChampionsCard,  CardContainer} from '../ChampionSectionV2/styles';
import UserCard from '../UserCardV2/index';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

class WellnessAdmins extends React.Component {

  showUserCards = (data) => (
    data.map((admin, index) => (
      <ChampionsCard key={index} margin={index >0 && (index === 3 || index === 7 ||index === 11 || index === 15) ||index === 19||index === 23||index === 27? '0px 0px 15px 0':'0px 15px 15px 0'}>
        <div>
          <UserCard data={admin}/>
        </div>
      </ChampionsCard>
    ))
  )

  render() {
    const {wellnessAdmin} = this.props;
    return(
      <ChampionTab style={{width:"1246px",margin:"auto"}}>
        <div>
          {isEmpty(wellnessAdmin) ?
            <CardContainer style={{marginTop:"35px"}}>
              <h3>No wellness Admins found</h3>
            </CardContainer> : null}
          <CardContainer style={{marginTop:"35px"}}>
            {isEmpty(wellnessAdmin) ? '' : wellnessAdmin.map((admin, index) => isEmpty(admin) ? <CardContainer key={index}>
              <h3>No wellness Admins found</h3>
            </CardContainer> : this.showUserCards(admin) )}
          </CardContainer>
        </div>
      </ChampionTab>
    );
  }
}

WellnessAdmins.propTypes = {
  wellnessAdmin: PropTypes.array,
};
export default WellnessAdmins;